import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class AdminDocs extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        return (
            <StyledComponent
                className="page-activity-logs"
                styles={require('./styles')}
            >
                <iframe className="iframe" src="/docs-admin-cENJ5h9Hnmr2uJhp" frameBorder="0" />
            </StyledComponent>
        );
    }
}
